import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class HistoriaService {
    async getHistoriasAll() {
        const historias = await fetchWrapper.get(`${ruta}/historia`);
        return historias;
    }

    async buscarFichas(datos) {
        return await fetchWrapper.post(`${ruta}/fichas_consultas`, datos);
    }

    async saveHistoria(datos) {
        return await fetchWrapper.post(`${ruta}/historia`, datos);
    }
    async historiaPaciente(datos) {
        return await fetchWrapper.post(`${ruta}/historia_cliente`, datos);
    }

    async updateHistoria(historia) {
        const historiaUpdate = await fetchWrapper.put(
            `${ruta}/historia/` + historia.id,
            historia
        );
        return historiaUpdate;
    }

    async noSeAtendio(historia) {
        const historiaUpdate = await fetchWrapper.put(
            `${ruta}/historia_no_atendio/` + historia.id,
            historia
        );
        return historiaUpdate;
    }

    async filtrarHistorias(datos) {
        return await fetchWrapper.post(`${ruta}/historia_filtrar`, datos);
    }

    async exportarHistorias(datos) {
        return await fetchWrapper.postWithBlob(`${ruta}/historia_exportar`, datos);
    }

    async saveEcoAbdominal(datos) {
        return await fetchWrapper.post(`${ruta}/save_eco_abdominal`, datos);
    }

    async savePrescripcion(datos) {
        return await fetchWrapper.post(`${ruta}/historia/prescripcion`, datos);
    }

    async getPrescripcion(datos) {
        return await fetchWrapper.post(`${ruta}/historia/get_prescripcion`, datos);
    }

    async printPrescripcion(datos) {
        return await fetchWrapper.postPdf(`${ruta}/historia/print_prescripcion`, datos);
    }

    async saveEcoGinecologico(datos) {
        return await fetchWrapper.post(`${ruta}/save_eco_ginecologico`, datos);
    }

    async saveEcoObstetrico(datos) {
        return await fetchWrapper.post(`${ruta}/save_eco_obstetrico`, datos);
    }

    async saveEcoMonitoreoFetal(datos) {
        return await fetchWrapper.post(`${ruta}/save_eco_monitoreo_fetal`, datos);
    }

    async printHistoriaGeneral(datos) {
        return await fetchWrapper.postPdf(`${ruta}/print_historia_general`, datos);
    }

    async printEcoAbdominal(datos) {
        return await fetchWrapper.postPdf(`${ruta}/print_eco_abdominal`, datos);
    }

    async printEcoGinecologico(datos) {
        return await fetchWrapper.postPdf(`${ruta}/print_eco_ginecologico`, datos);
    }

    async printEcoObstetrico(datos) {
        return await fetchWrapper.postPdf(`${ruta}/print_eco_obstetrico`, datos);
    }

    async printEcoMonitoreoFetal(datos) {
        return await fetchWrapper.postPdf(`${ruta}/print_eco_monitoreo_fetal`, datos);
    }

}
